<template>
  <div class="career-banner">
    <div class="career-banner-img">
      <img src="@/assets/img/happy-cheerful-business-people.png" alt="" />
    </div>
    <div class="career-banner-content">
      <h4>Build the future of tokenization with us</h4>
      <button>Start Here</button>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.career-banner {
  background: var(--mainLightGreenColor);
  width: 100%;
  max-height: 600px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  padding: 80px 100px;
}

.career-banner-content {
  width: 38%;
  margin-left: auto;
}

.career-banner h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 61px;
  color: #000000;
  margin-bottom: 30px;
}

.career-banner img {
  width: 100%;
  height: auto;
  border-radius: 2px;
}

.career-banner-content button {
  border: none;
  background: var(--mainGreenColor);
  border-radius: 5px;
  padding: 11px 40px;
  color: #fff;
}

.career-banner-content button:hover {
  background: #fff;
  color: var(--mainGreenColor);
}

@media screen and (max-width: 1400px) {
  .career-banner {
    max-height: 600px;
    padding: 80px;
  }

  .career-banner-content {
    width: 42%;
    margin-left: 60px;
  }
}
@media screen and (max-width: 1150px) {
  .career-banner {
    padding: 80px 60px;
  }

  .career-banner h4 {
    font-size: 40px;
    /* margin-bottom: 60px; */
  }
}

@media screen and (max-width: 1000px) {
  .career-banner-content {
    width: 85%;
    margin-left: 60px;
  }

  .career-banner h4 {
    font-size: 35px;
    line-height: 41px;
  }
}
@media screen and (max-width: 900px) {
  .career-banner {
    padding: 60px 35px;
  }

  .career-banner-content {
    width: 95%;
    margin-left: 60px;
  }

  .career-banner h4 {
    font-size: 30px;
  }
}

@media screen and (max-width: 720px) {
  .career-banner {
    padding: 40px 25px;
    min-height: 300px;
  }

  .career-banner-content {
    width: 100%;
    margin-left: 40px;
  }

  .career-banner h4 {
    font-size: 25px;
    /* margin-bottom: 60px; */
  }
}

@media screen and (max-width: 680px) {
  .career-banner-img {
    display: none;
  }

  .career-banner-content {
    width: 100%;
    text-align: center;
    margin-left: 0px;
  }
}

@media screen and (max-width: 580px) {
  .career-banner-content {
    width: 88%;
    margin: auto;
  }

  .career-banner h4 {
    font-size: 27px;
  }
}
@media screen and (max-width: 480px) {
  .career-banner {
    padding: 30px 20px;
  }
  .career-banner h4 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  .career-banner-content button {
    padding: 11px 40px;
    font-size: 13px;
  }
}
@media screen and (max-width: 380px) {
  .career-banner-content {
    width: 98%;
    margin: auto;
  }
  .career-banner h4 {
    font-size: 23px;
  }
}
</style>

<template>
  <div class="core_values">
    <h4>Our Core <span class="text-green">Values</span></h4>
    <div class="core_values_grid">
      <div class="core_values_grid_item">
        <h5>Integrity</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
          suspendisse risus eget dolor ultrices quisque donec ut habitant. Quis
        </p>
      </div>
      <div class="core_values_grid_item yellow_bg">
        <h5>Collaboration</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
          suspendisse risus eget dolor ultrices quisque donec ut habitant. Quis
        </p>
      </div>
      <div class="core_values_grid_item yellow_bg">
        <h5>Collaboration</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
          suspendisse risus eget dolor ultrices quisque donec ut habitant. Quis
        </p>
      </div>
      <div class="core_values_grid_item">
        <h5>Integrity</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
          suspendisse risus eget dolor ultrices quisque donec ut habitant. Quis
        </p>
      </div>
      <div class="core_values_grid_item">
        <h5>Integrity</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
          suspendisse risus eget dolor ultrices quisque donec ut habitant. Quis
        </p>
      </div>
      <div class="core_values_grid_item yellow_bg">
        <h5>Collaboration</h5>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
          suspendisse risus eget dolor ultrices quisque donec ut habitant. Quis
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.core_values {
  background: #fff;
  width: 100%;
  padding: 80px 100px;
}

.core_values h4 {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 63px;
  color: #2d2d2d;
}

.core_values_grid {
  width: 70%;
  margin: 60px auto 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  align-items: center;
  column-gap: 60px;
}

.core_values_grid_item {
  background: #eff6f5;
  border-radius: 20px;
  padding: 40px;
}

.yellow_bg {
  background: var(--mainLightYellowColor);
}

.core_values_grid_item h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 38px;
  color: #2d2d2d;
  margin-bottom: 19px;
}

.core_values_grid_item p {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

@media screen and (max-width: 1300px) {
  .core_values {
    width: 100%;
    padding: 60px 80px;
  }

  .core_values h4 {
    font-size: 39px;
  }

  .core_values_grid {
    width: 80%;
    margin: 60px auto 50px;
    gap: 40px 60px;
  }

  .core_values_grid_item h5 {
    font-size: 20px;
  }
}

@media screen and (max-width: 1080px) {
  .core_values_grid {
    width: 100%;
    margin: 60px auto 50px;
    gap: 40px 60px;
  }
}

@media screen and (max-width: 950px) {
  .core_values {
    padding: 60px 40px;
  }
}

@media screen and (max-width: 850px) {
  .core_values {
    padding: 60px 30px;
  }

  .core_values_grid {
    gap: 40px 30px;
  }
}

@media screen and (max-width: 750px) {
  .core_values {
    padding: 60px 20px;
  }

  .core_values_grid {
    gap: 40px 15px;
  }

  .core_values_grid_item {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 600px) {
  .core_values h4 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .core_values_grid {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 22px;
    margin-bottom: 0px;
  }

  .core_values_grid_item {
    padding: 40px 30px;
    border-radius: 10px;
    /* margin-bottom: 30px; */
  }

  .core_values_grid_item.yellow_bg {
    background: none;
  }
  .core_values_grid_item:nth-of-type(odd) {
    background: #eff6f5 !important;
  }
  .core_values_grid_item:nth-of-type(even) {
    background: var(--mainLightYellowColor) !important;
  }
}
</style>

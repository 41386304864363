<template>
  <div class="open_roles">
    <h4>{{ header }}</h4>
    <div class="open_roles_grid" v-if="type == 'FAQ' && data && data.length">
      <div
        class="open_roles_grid_item"
        v-for="(faq, index) in data"
        :key="index"
      >
        <div class="open_roles_grid_item_header">
          <h5>{{ faq.title }}</h5>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="open_roles_grid_item_body">
          <p>
            {{ faq.content }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="open_roles_grid"
      v-if="type == 'CAREERS' && data && data.length"
    >
      <div
        class="open_roles_grid_item"
        v-for="(career, index) in data"
        :key="index"
      >
        <div class="open_roles_grid_item_header">
          <h5>{{ career.title }}</h5>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="open_roles_grid_item_body">
          <p>
            {{ career.content }}
          </p>
          <button class="relsify-button">Apply</button>
        </div>
      </div>
      <!-- <div class="open_roles_grid_item">
        <div class="open_roles_grid_item_header">
          <h5>Business Development Specialists</h5>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="open_roles_grid_item_body">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis
          </p>
        </div>
      </div>
      <div class="open_roles_grid_item">
        <div class="open_roles_grid_item_header">
          <h5>Business Development Specialists</h5>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="open_roles_grid_item_body">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis
          </p>
        </div>
      </div>
      <div class="open_roles_grid_item">
        <div class="open_roles_grid_item_header">
          <h5>Business Development Specialists</h5>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="open_roles_grid_item_body">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis
          </p>
        </div>
      </div>
      <div class="open_roles_grid_item">
        <div class="open_roles_grid_item_header">
          <h5>Business Development Specialists</h5>
          <i class="fa fa-chevron-right"></i>
        </div>
        <div class="open_roles_grid_item_body">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis
            <br />
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel,
            suspendisse risus eget dolor ultrices quisque donec ut habitant.
            Quis
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "header", "data"],
};
</script>

<style scoped>
.open_roles {
  background: #fff;
  width: 100%;
  padding: 80px 100px;
}

.open_roles h4 {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 63px;
  color: #2d2d2d;
}

.open_roles_grid {
  width: 70%;
  margin: 70px auto 40px;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
}

.open_roles_grid_item {
  background: #eff6f5;
  border-radius: 8px;
}

.yellow_bg {
  background: var(--mainLightYellowColor);
}

.open_roles_grid_item_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  cursor: pointer;
}

.open_roles_grid_item_header i {
  color: #000000;
  /* font-weight: bold; */
  font-size: 17px;
  transition: all 400ms ease-in-out;
}

.open_roles_grid_item.active .open_roles_grid_item_header i::before {
  content: "\f078";
  color: #000000;
}

.open_roles_grid_item h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 30px;
  color: #000000;
  margin: 0px;
}

.open_roles_grid_item_body {
  padding: 20px;
  text-align: left;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  /* transition: all 400ms ease-in-out; */
  display: none;
}

/* .open_roles_grid_item.active .open_roles_grid_item_body {
  display: block;
} */

.open_roles_grid_item p {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: #000000;
}

.open_roles_grid_item button.relsify-button {
  padding: 10px 40px;
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
}

@media screen and (max-width: 1200px) {
  .open_roles {
    width: 100%;
    padding: 60px 80px;
  }

  .open_roles h4 {
    font-size: 39px;
  }

  .open_roles_grid {
    width: 80%;
    margin: 70px auto 40px;
  }
}

@media screen and (max-width: 1080px) {
  .open_roles_grid {
    width: 100%;
  }
}

@media screen and (max-width: 950px) {
  .open_roles {
    padding: 60px 40px;
  }
}

@media screen and (max-width: 850px) {
  .open_roles {
    padding: 60px 30px;
  }
}

@media screen and (max-width: 750px) {
  .open_roles {
    padding: 60px 20px;
  }

  .open_roles_grid {
    margin-bottom: 0px;
  }

  .open_roles_grid_item h5 {
    font-size: 15px;
  }

  .open_roles_grid_item_header {
    padding: 15px 20px;
  }

  .open_roles_grid_item_header i {
    font-size: 15px;
  }

  .open_roles_grid_item p {
    font-size: 14.5px;
  }
}

@media screen and (max-width: 600px) {
  .open_roles h4 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .open_roles_grid_item h5 {
    font-size: 13.5px;
  }
}
</style>

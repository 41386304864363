<template>
  <div>
    <CareerBanner />
    <CoreValues />
    <OpenRolesAccordion :type="'CAREERS'" :header="header" :data="null" />
  </div>
</template>

<script>
import CareerBanner from "../components/extras/banners/CareerBanner.vue";
import CoreValues from "../components/extras/CoreValues.vue";
import OpenRolesAccordion from "../components/extras/OpenRolesAccordion.vue";
import { mapActions } from "vuex";
export default {
  components: {
    CareerBanner,
    CoreValues,
    OpenRolesAccordion,
  },

  data() {
    return {
      header: "Open Roles",
    };
  },

  methods: {
    ...mapActions(["setCurrentPageName"]),
  },

  mounted() {
    this.setCurrentPageName(this.$router.currentRoute._rawValue.name);
  },
};
</script>
